import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    staticClass: "mx-4 my-2 rounded-lg",
    attrs: {
      "to": _vm.url,
      "width": "190",
      "disabled": !_vm.disabled
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c(VProgressLinear, {
    attrs: {
      "color": "primary",
      "height": "10",
      "indeterminate": ""
    }
  })], 1), _c(VImg, {
    attrs: {
      "height": "190",
      "src": _vm.imgsrc
    }
  }), _c(VCardTitle, {
    staticClass: "justify-center"
  }, [_vm._v(_vm._s(_vm.name))])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };