import { mapGetters } from 'vuex';
import HomeAppCard from '@/components/home/HomeAppCard.vue';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import store from '@/store';
export default {
  data() {
    return {
      tab: 0,
      openDialog: false,
      appName: ''
    };
  },
  components: {
    SiteHeaderComponent,
    HomeAppCard
  },
  computed: {
    ...mapGetters(['availableShopApps', 'lbaShopInfoApps', 'lbaShopBdeApps', 'lbaShopAzeApps'])
  },
  methods: {
    openDialogMethod(appName) {
      this.openDialog = true;
      this.appName = appName;
    }
  },
  created: () => {
    store.dispatch('loadShopAppsAvailableForCurrentUser');
  }
};