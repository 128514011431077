import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('SiteHeaderComponent', {
    attrs: {
      "header": "Shop",
      "showBackButton": true
    }
  }), _vm.lbaShopInfoApps.length == 0 && _vm.lbaShopBdeApps.length == 0 && _vm.lbaShopAzeApps.length == 0 ? _c(VContainer, [_c('h3', {
    staticClass: "mt-4 pa-4 pl-8 text-center",
    staticStyle: {
      "font-size": "2em"
    }
  }, [_vm._v(" Sie besitzen bereits alle LBA-Apps ")])]) : _vm._e(), _vm.lbaShopInfoApps.length != 0 ? _c(VContainer, [_c('h2', {
    staticClass: "mt-4 white pa-4 pl-8 elevation-2 rounded-lg",
    staticStyle: {
      "font-size": "2em"
    }
  }, [_c(VIcon, {
    staticClass: "pr-4",
    attrs: {
      "color": "black",
      "size": "1.2em"
    }
  }, [_vm._v("mdi-information-outline")]), _vm._v(" LBA-INFO ")], 1), _c(VContainer, {
    staticClass: "d-flex flex-wrap justify-center justify-md-start"
  }, _vm._l(_vm.lbaShopInfoApps, function (app) {
    return _c('HomeAppCard', {
      key: app.appId,
      attrs: {
        "name": app.appBezeichnung,
        "url": app.url
      },
      on: {
        "openDialog": function ($event) {
          return _vm.openDialogMethod(app.appBezeichnung);
        }
      }
    });
  }), 1)], 1) : _vm._e(), _vm.lbaShopBdeApps.length != 0 ? _c(VContainer, [_c('h2', {
    staticClass: "mt-4 white pa-4 pl-8 elevation-2 rounded-lg",
    staticStyle: {
      "font-size": "2em"
    }
  }, [_c(VIcon, {
    staticClass: "pr-4",
    attrs: {
      "color": "black",
      "size": "1.2em"
    }
  }, [_vm._v("mdi-chart-timeline-variant")]), _vm._v(" LBA-BDE ")], 1), _c(VContainer, {
    staticClass: "d-flex flex-wrap justify-center justify-md-start"
  }, _vm._l(_vm.lbaShopBdeApps, function (app) {
    return _c('HomeAppCard', {
      key: app.appId,
      attrs: {
        "name": app.appBezeichnung
      },
      on: {
        "openDialog": function ($event) {
          return _vm.openDialogMethod(app.appBezeichnung);
        }
      }
    });
  }), 1)], 1) : _vm._e(), _vm.lbaShopAzeApps.length != 0 ? _c(VContainer, [_c('h2', {
    staticClass: "mt-4 white pa-4 pl-8 elevation-2 rounded-lg",
    staticStyle: {
      "font-size": "2em"
    }
  }, [_c(VIcon, {
    staticClass: "pr-4",
    attrs: {
      "color": "black",
      "size": "1.2em"
    }
  }, [_vm._v("mdi-calendar-month")]), _vm._v(" LBA-AZE ")], 1), _c(VContainer, {
    staticClass: "d-flex flex-wrap justify-center justify-md-start"
  }, _vm._l(_vm.lbaShopAzeApps, function (app) {
    return _c('HomeAppCard', {
      key: app.appId,
      attrs: {
        "name": app.appBezeichnung
      },
      on: {
        "openDialog": function ($event) {
          return _vm.openDialogMethod(app.appBezeichnung);
        }
      }
    });
  }), 1)], 1) : _vm._e(), _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "700"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.openDialog = false;
      }
    },
    model: {
      value: _vm.openDialog,
      callback: function ($$v) {
        _vm.openDialog = $$v;
      },
      expression: "openDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "dark": "",
      "fixed": "",
      "top": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.openDialog = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("App: " + _vm._s(_vm.appName))])], 1)], 1), _c(VDivider), _c(VCardText, {
    staticClass: "pa-8"
  }, [_c('h2', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.appName))]), _vm.appName == 'LBA-Artikel' ? _c('p', [_vm._v(" Die LBA-Artikel App ist dafür da ihre Artikel mit einer Volltextsuche, einem Filter nach vielen verschiedenen Merkmalen oder auch einem Barcode Scanner zu suchen und viele Daten des Artikels anzusehen. Neben den allgemeinen Informationen gibt es auch eine Statistik die Informationen über den Umsatz des Artikels bereitstellen ")]) : _vm._e(), _vm.appName == 'LBA-CRM' ? _c('p', [_vm._v(" Die LBA-CRM App ist dafür da ihre Adressen mit einer Volltextsuche oder einem Filter zu suchen und verschiedenste Informationen anzusehen. ")]) : _vm._e(), _vm.appName == 'LBA-Auftrag' ? _c('p', [_vm._v(" Die LBA-Auftrag App kann dafür verwendet werden, dass Sie einen Auftrag oder ein Angebot erstellt. Den Auftrag können Sie sich nach der Erstellung in der Dokumentansicht ansehen. Coming soon: Auftrag aus Angebot erstellen. ")]) : _vm._e(), _vm._v(" Falls Sie " + _vm._s(_vm.appName) + " benutzen wollen, melden sie sich bei: "), _c('br'), _vm._v(" E-Mail: ??? "), _c('br'), _vm._v(" Telefonnummer: ??? ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };