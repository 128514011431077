import statisticsLogo from '@/assets/svg/lba-apps/LBA-Statistik.svg';
import calLogo from '@/assets/svg/lba-apps/LBA-Kalender.svg';
import crmLogo from '@/assets/svg/lba-apps/LBA-CRM.svg';
import artSearchLogo from '@/assets/svg/lba-apps/LBA-ArticleSearch.svg';
import businessLogo from '@/assets/svg/lba-apps/LBA-Business.svg';
import devLogo from '@/assets/svg/lba-apps/LBA-DEV.svg';
import shopLogo from '@/assets/svg/lba-apps/LBA-Shop.svg';
import taskLogo from '@/assets/svg/lba-apps/LBA-Task.svg';
import settingsLogo from '@/assets/svg/lba-apps/LBA-Settings.svg';
export default {
  name: 'HomeAppCard',
  props: {
    name: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    imgsrc() {
      if (this.name == 'Statistik') {
        return statisticsLogo;
      } else if (this.name == 'Kalender' || this.name == 'Kalender-v2') {
        return calLogo;
      } else if (this.name == 'CRM') {
        return crmLogo;
      } else if (this.name == 'Entwicklerportal') {
        return devLogo;
      } else if (this.name == 'Artikel') {
        return artSearchLogo;
      } else if (this.name == 'LBA-Shop') {
        return shopLogo;
      } else if (this.name == 'Business') {
        return businessLogo;
      } else if (this.name == 'Einstellungen') {
        return settingsLogo;
      } else if (this.name == 'Task') {
        return taskLogo;
      }
      return require('@/assets/LBA_logo_big.png');
    }
  },
  methods: {
    openDialog() {
      this.$emit('openDialog');
    },
    openUrl: function (url) {
      var win = window.open(url);
      win.focus();
    }
  }
};